import Vue from 'vue'
import Vuex from 'vuex'
import cms from './cms'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        cms,
    },
})
export default store;