import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue } from "bootstrap-vue";
import PrismicVue from "@prismicio/vue";
import axios from "axios";
import store from "./store";
import "./app.scss";
import "animate.css";
Vue.prototype.$http = axios;
Vue.use(PrismicVue, {
  endpoint: "https://baan-tepa.prismic.io/api/v2",
  linkResolver: function(doc) {
    if (doc.type === "home") {
      return "/";
    }
  },
});
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
async function Main() {
  await store.dispatch("cms/getRefCms");
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
Main();
