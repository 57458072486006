<template>
  <b-row
    v-if="info"
    align-v="center"
    align-h="center"
    class="tepa-footer bg-red"
  >
    <b-col col lg="10" md="12" sm="12">
      <b-row
        align-v="center"
        class="d-flex justify-content-md-between justify-content-center"
      >
        <b-col cols lg="auto" md="auto" class="d-none d-sm-none d-md-block">
          <img :src="require(`@/assets/logo.svg`)" />
        </b-col>
        <b-col cols="auto">
          <b-row align-v="center" align-h="end">
            <b-col
              cols="12"
              lg="auto"
              md="auto"
              class="text-md-left text-center"
            >
              <prismic-rich-text class="tepa-p text-white" :field="info.info" />
            </b-col>
            <div class="line d-none d-sm-none d-md-block"></div>
            <b-col
              cols="12"
              lg="auto"
              md="auto"
              class="text-md-left text-center pt-2 pt-md-0"
            >
              <img
                @click="toPage(info.link_fb.url)"
                :src="require(`@/assets/facebook-icon.svg`)"
                style="margin-right: 17px; cursor: pointer"
              />
              <img
                @click="toPage(info.link_ig.url)"
                :src="require(`@/assets/instargram-icon.svg`)"
                style="cursor: pointer"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      info: null,
    };
  },
  mounted() {
    this.getFooter();
  },
  methods: {
    async getFooter() {
      const params = {
        ref: this.ref,
        q: '[[at(document.tags, ["Footer"])]]',
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      this.info = res.data.results[0].data;
      console.log(this.info);
    },
    toPage(url) {
      if (url.startsWith("http")) {
        let win = window.open(url, "_blank");
        win.focus();
      } else {
        this.$router.push(url);
      }
    },
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
  },
};
</script>

<style scoped>
>>> p {
  margin: 0px !important;
}
.tepa-footer {
  height: 127px;
  width: 100%;
  margin: 0px;
}
.tepa-p {
  font-family: Open Sans;
}
.line {
  width: 1px;
  height: 49px;
  margin: 0 30px;
  background-color: white;
}
>>> a {
  color: white;
}
>>> a:hover {
  color: white;
  text-decoration: underline;
}
</style>