import axios from "axios";

const initialState = {
  ref: null,
  popup: null,
  alert: null,
};
const cms = {
  namespaced: true,
  state: initialState,
  getters: {
    ref: (state) => {
      return state.ref;
    },
    popup: (state) => {
      return state.popup;
    },
    alert: (state) => {
      return state.alert;
    },
  },
  mutations: {
    setRef(state, val) {
      state.ref = val;
    },
    setPopup(state, val) {
      if (state.popup === null || val === false) {
        state.popup = val;
      }
    },
    setAlert(state, val) {
      if (state.alert === null || val === false) {
        state.alert = val;
      }
    },
  },
  actions: {
    async getRefCms(context) {
      try {
        const res = await axios.get(process.env.VUE_APP_URL + "/api/v2");
        context.commit("setRef", res.data.refs[0].ref);
      } catch (error) {
        console.log(error);
      }
    },
    async setPopup(context, val) {
      context.commit("setPopup", val);
    },
    async setAlert(context, val) {
      context.commit("setAlert", val);
    },
  },
};
export default cms;
