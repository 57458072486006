<template>
  <div id="app">
    <Header />
    <keep-alive>
      <router-view />
    </keep-alive>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
// import AOS from "aos";
// import "aos/src/sass/aos.scss";
export default {
  components: {
    Header,
    Footer,
  },
  created() {
    // AOS.init({ once: true, offset: 0, startEvent: "load" });
  },
};
</script>
<style>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
