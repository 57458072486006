<template>
  <div class="fixed">
    <b-navbar
      toggleable="lg"
      :type="`${target || collapse ? 'light' : 'dark'}`"
      class="text-white nav-bar-custom"
    >
      <b-collapse
        id="nav-collapse"
        :class="`${isMobile ? 'show-collapse' : ''}`"
        is-nav
        v-model="collapse"
      >
        <b-row
          align-v="center"
          align-h="center"
          style="width: 100%; margin: 0px"
        >
          <b-col
            col
            lg="12"
            md="12"
            style="
              display: flex;
              justify-content: center;
              height: 100%;
              padding: 0 3%;
            "
          >
            <!-- <b-navbar-nav class="d-md-none d-lg-none d-md-block">
              <div
                v-for="item in headerListMobile"
                :key="item.id"
                :class="`${item.img && collapse ? 'd-none' : ''}`"
                style="flex: 1;"
              >
                <b-nav-item
                  :style="`font-size:${collapse ? 24 : ''}px`"
                  v-if="item.primary"
                  :active="
                    active === item.primary.url_path.length > 0
                      ? item.primary.url_path[0].text
                      : null
                  "
                  @click="toPage(item)"
                  >{{ item.primary.label[0].text }}</b-nav-item
                >
                <div v-else style="cursor: pointer" @click="$router.push('/')">
                  <img
                    v-if="!target && !collapse"
                    class="img-logo"
                    :src="require(`@/assets/logo.svg`)"
                    alt="..."
                  />
                  <div v-else>
                    <img
                      v-if="!collapse"
                      class="img-logo"
                      :src="require(`@/assets/logo-black.svg`)"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </b-navbar-nav> -->
            <b-navbar-nav>
              <div class="tepa-nav-item">
                <div
                  v-for="item in headerList[0]"
                  :key="item.id"
                  :class="`${item.img && collapse ? 'd-none' : ''}`"
                >
                  <b-nav-item
                    :style="`font-size:${collapse ? 24 : ''}px`"
                    v-if="item.primary"
                    :active="
                      active === item.primary.url_id.length > 0
                        ? item.primary.url_id[0].text
                        : null
                    "
                    @click="toPage(item)"
                    >{{ item.primary.label[0].text }}</b-nav-item
                  >
                </div>
              </div>
              <div style="cursor: pointer" @click="$router.push('/')">
                <img
                  v-if="!target && !collapse"
                  class="img-logo"
                  :src="require(`@/assets/logo.svg`)"
                  alt="..."
                />
                <div v-else>
                  <img
                    v-if="!collapse"
                    class="img-logo"
                    :src="require(`@/assets/logo-black.svg`)"
                    alt="..."
                  />
                </div>
              </div>
              <div class="tepa-nav-item">
                <div
                  v-for="item in headerList[2]"
                  :key="item.id"
                  :class="`${item.img && collapse ? 'd-none' : ''}`"
                >
                  <b-nav-item
                    :style="`font-size:${collapse ? 24 : ''}px`"
                    v-if="item.primary"
                    :active="
                      active === item.primary.url_path.length > 0
                        ? item.primary.url_path[0].text
                        : null
                    "
                    @click="toPage(item)"
                    >{{ item.primary.label[0].text }}</b-nav-item
                  >
                </div>
              </div>
            </b-navbar-nav>
          </b-col>
        </b-row>
      </b-collapse>
      <div class="position-absolute" style="right: 20px; z-index: 2">
        <b-navbar-toggle
          v-if="!collapse"
          class="custom-toggler"
          target="nav-collapse"
        >
          <span class="navbar-toggler-icon"></span>
        </b-navbar-toggle>
        <img
          style="padding: 0.25rem 1rem 0.25rem 0; height: 30px; cursor: pointer"
          @click="collapse = false"
          v-else
          :src="require('@/assets/close-icon.svg')"
        />
      </div>
      <div
        class="position-absolute d-lg-none d-xl-none"
        style="
          cursor: pointer;
          text-align: center;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        "
        @click="$router.push('/')"
      >
        <img
          v-if="!target && !collapse"
          class="img-logo"
          :src="require(`@/assets/logo.svg`)"
          alt="..."
        />
        <img
          v-else
          class="img-logo"
          :src="require(`@/assets/logo-black.svg`)"
          alt="..."
        />
      </div>
    </b-navbar>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      refCms: null,
      headerList: [],
      headerListMobile: [],
      target: false,
      collapse: false,
      active: null,
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
    isMobile() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      return width <= 992;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.getHeader();
  },
  watch: {
    $route(to, from) {
      if (to.name === "Information Page") {
        this.target = true;
        document.querySelector(
          ".navbar"
        ).style.backgroundColor = `rgba(226, 225, 217,${0.97})`;
      } else {
        this.target = false;
        document.querySelector(
          ".navbar"
        ).style.backgroundColor = `rgba(226, 225, 217,${0})`;
      }
    },
  },
  methods: {
    async getHeader() {
      const params = {
        ref: this.ref,
        q: '[[at(document.tags, ["Header"])]]',
        orderings: "[my.Header.priority]",
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      let data = [[], { img: null }, []];
      this.headerList = res.data.results[0].data.body;
      this.headerListMobile = this.headerList;
      for (let i = 0; i <= this.headerList.length - 1; i++) {
        if (i < this.headerList.length / 2) {
          data[0].push(this.headerList[i]);
        } else if (i === this.headerList.length / 2) {
          data[2].push(this.headerList[i]);
          data[1] = {
            img: true,
            id: "img",
          };
        } else if (i > this.headerList.length / 2) {
          data[2].push(this.headerList[i]);
        }
      }
      this.headerList = data;
      // this.headerList.splice(this.headerList.length / 2, 0, {
      //   img: true,
      //   id: "img",
      // });
      console.log(this.headerList);
      // [[],{},[]]
    },
    toPage(item) {
      this.active =
        item.primary.url_id.length > 0 ? item.primary.url_id[0].text : null;
      if (this.$router.currentRoute.path === "/") {
        $("html, body").animate(
          { scrollTop: $(`#${item.primary.url_id[0].text}`).offset().top - 70 },
          1000
        );
      } else {
        if (item.primary.url_path.length > 0) {
          this.$router.push(`${item.primary.url_path[0].text}`);
        } else {
          console.log(item.primary.url_id[0].text);
          this.$router.push({
            name: "Home",
            query: { id: item.primary.url_id[0].text },
          });
        }
      }
      console.log(this.active);
    },
    onScroll() {
      if (this.$router.currentRoute.name === "Information Page") {
        this.target = true;
        document.querySelector(
          ".navbar"
        ).style.backgroundColor = `rgba(226, 225, 217,${0.97})`;
      } else {
        const checkpoint =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        let opacity = 0;
        const currentScroll = window.pageYOffset;
        if (currentScroll <= checkpoint) {
          opacity = currentScroll / checkpoint;
        } else {
          opacity = 0.97;
        }
        if (opacity > 0.5) this.target = true;
        else this.target = false;
        document.querySelector(
          ".navbar"
        ).style.backgroundColor = `rgba(226, 225, 217,${opacity})`;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  padding: 0;
  justify-content: center;
  height: 90px;
}
.navbar-nav {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 0;
  font-size: 19px;
  font-weight: 600;
  font-family: Cormorant;
}
.show-collapse {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 120px 0 50px;
  // height: 100vh;
}
.collapse:not(.show) {
  padding: 0px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #828282;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #828282;
}

// .navbar-collapse {
//   flex-basis: 100%;
//   flex-grow: 1;
//   align-items: center;
//   height: 100vh;
//   background-color: white;
// }
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1);
}
.navbar-dark .navbar-toggler {
  border: unset;
}
.navbar-light .navbar-toggler {
  border: unset;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.tepa-nav-item {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 992px) {
  .img-logo {
    height: 36px;
  }
  .navbar {
    height: 70px;
  }
  .tepa-nav-item {
    flex-direction: column;
  }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: #740b1b;
  }
}
</style>